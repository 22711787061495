import React from "react";
import { makeStyles } from "@mui/styles";
import { ReactComponent as MahLogo } from "../resources/images/mah-logo.svg";
import { ReactComponent as MagLogo } from "../resources/images/mag-logo.svg";

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: "center",
    maxWidth: "240px",
    maxHeight: "120px",
    width: "auto",
    height: "auto",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <MahLogo className={classes.image} style={{ color }} />
      <MagLogo className={classes.image} style={{ color }} />
    </div>
  );
};

export default LogoImage;
